<template>
	<div>
		<asideBar :navMenuList="detailInfo.growthProcess" />
		<div class="container mt-4"
			:style="{ 'margin-left': $store.state.isMobile ? '0px' : $store.state.collapse ? '200px' : '60px' }">
			<main class="content">
				<div class="content-wrap">
					<div class="content-layout">
						<div class="panel">
							<div class="pr-lg-4">
								<div class="panel-header mb-3">
									<h2 class="h3 font-weight-bold mb-3">关于我们</h2>
								</div>
								<div class="panel-body single mt-2">
									<p v-for="(item, key) in detailInfo.content" :key="key">
										{{ item }}
									</p>
									<div :id="'section' + (key + 1)" class="growth-box"
										v-for="(item, key) in detailInfo.growthProcess" :key="'growth' + key">
										<h3>
											<!-- <i :class="item.icon" /> --><b>{{ item.name }}</b>
										</h3>
										<template>
											<div class="card-grid" v-if="['其他分站', '赞助商'].includes(item.name)">
												<div v-for="(items, subKey) in item.children" :key="'children' + subKey"
													class="sponsors-card">
													<div class="sponsors-body">
														<a :href="items.href" target="_blank" rel="nofollow">
															<img v-if="items.src" class="lazy" :src="items.src"
																:alt="items.alt" />
														</a>
													</div>
													11
												</div>
											</div>
											<template v-if="['联系方式'].includes(item.name)">
												<div class="contact" v-for="(items, subKey) in item.children"
													:key="'children2' + subKey">
													<h4>{{ items.name }}</h4>
													<div v-for="(contactItem, contactKey) in items.contact"
														:key="'contact' + (subKey + '-' + contactKey)">
														<p class="copy-item">
															<span>{{ contactItem.label }}: </span>
															<span class="contactNumber">{{ contactItem.value }}</span>
															<a class="text-sm copy-btn"
																@click="copyHandle(contactItem.value)"><!-- <i class="iconfont icon-instructions mr-1"></i> -->复制</a>
														</p>
														<p v-if="contactItem.desc">{{ contactItem.desc }}</p>
													</div>
												</div>
											</template>
											<p v-else v-for="(items, subKey) in item.children"
												:key="'children3' + subKey">
												<span v-if="items.datetime">{{ items.datetime  }}</span>
												<a v-if="items.href" :href="items.href" target="_blank" rel="nofollow">

													<strong v-if="items.websiteName">{{ items.websiteName }}</strong>
												</a>
												<span v-if="items.desc">{{ items.desc }}</span>
											</p>
										</template>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</main>
		</div>
		<!-- 弹框 -->
		<asideDrawer :navMenuList="detailInfo.growthProcess" />
	</div>
</template>

<script>
	import asideBar from '@/views/layout/components/aside'
	import asideDrawer from '@/views/layout/components/asideDrawer'
	export default {
		components: {
			asideBar,
			asideDrawer
		},
		data() {
			return {
				detailInfo: {
					content: [
						''
					],
					growthProcess: [{
							id: 1,
							name: '其他分站',
							icon: 'io io-renzheng',
							children: [{
									src: require('@/assets/imgs/about/website01.jpg'),
									href: 'https://2sdh.com/',
									alt: '二叔导航'
								},
								{
									src: require('@/assets/imgs/about/website02.jpg'),
									href: 'https://16ys.top/',
									alt: '一流影视导航'
								},
								{
									src: require('@/assets/imgs/about/website03.jpg'),
									href: 'https://nav.zzzmh.top/',
									alt: '极简导航'
								}
							]
						},
						{
							id: 2,
							name: '成长历程',
							icon: 'io io-xindong',
							children: [{
									datetime: '2024年06月08日',
									icon: ' 🎉 ',
									href: 'https://jutusou.huamao.vip/',
									websiteName: '聚图搜',
									desc: '网站'
								},
								{
									datetime: '2024年04月10日',
									icon: ' 📈 ',
									href: 'https://jutusou.huamao.vip/',
									desc: '24小时用户活跃成功突破一万'
								},
								{
									datetime: '2024年03月23日',
									icon: ' 🫂助力 ',
									href: 'https://2sdh.com/',
									websiteName: '二叔导航',
									desc: '网站创立'
								},
								{
									datetime: '2023年09月05日',
									icon: ' 🤝与 ',
									href: 'https://yuancy.net/',
									websiteName: '二次元',
									desc: ' 成为合作伙伴，为将来数据交互贡献一份力'
								},
								{
									datetime: '2023年02月02日',
									icon: ' ⏫︎',
									desc: '网站UI界面重新改版'
								},
								{
									datetime: '2020年11月10日',
									icon: ' 🎉',
									desc: '网站正式上线并创立'
								}
							]
						},
						{
							id: 3,
							name: '网站更新日志',
							icon: 'io io-faxian',
							children: [{
									datetime: '2024年05月04日',
									desc: '在网站内容基础上增加了公司和人物的两种内容'
								},
								{
									datetime: '2024年04月12日',
									desc: '增加了网站热度以及网站流量情况统计'
								},
								{
									datetime: '2024年03月26日',
									desc: '新增用户系统（接入花猫单点登录系统）'
								},
								{
									datetime: '2024年02月16日',
									desc: '优化网站结构并且减少代码冗余，来缓解低端设备性能问题'
								},
								{
									datetime: '2024年02月11日',
									desc: '新增涂鸦（Doodles）'
								},
								{
									datetime: '',
									desc: ''
								}
							]
						},
						{
							id: 4,
							name: '赞助商',
							icon: 'io io-chongzhi',
							children: [{
								src: require('@/assets/imgs/about/sponsor01.jpg'),
								href: 'https://dw.y4may5vp.com/kgq/63-kgqxz2_1_6.apk'
							}]
						},
						{
							id: 5,
							name: '联系方式',
							icon: 'io io-xiaoxi',
							children: [{
									name: '站长',
									contact: [{
											label: 'QQ',
											value: '1147979142'
										},
										{
											label: '微信号',
											value: 'hhj1147979142'
										},
										{
											label: '邮箱',
											value: '1147979142@qq.com'
										}
									]
								},
								{
									name: 'QQ群',
									contact: [{
											label: '用户交流',
											value: '974178961'
										},
										{
											label: '导航网站交流',
											value: '974178961',
											desc: '（注：只允许导航网站的站长加入）'
										}
									]
								}
							]
						}
					]
				}
			}
		},
		created() {},

		methods: {
			copyHandle(text) {
				if (navigator.clipboard && window.isSecureContext) {
					navigator.clipboard.writeText(text)
					this.$message.success('复制成功')
				}
			}
		}
	}
</script>

<style>
	@import url('../css/bootstrap.css');
	@import url('../css/style.css');
</style>
<style scoped>
	.copy-item {
		display: inline-block;
		padding-right: 10px;
	}

	.copy-item:hover .copy-btn {
		display: inline-block;
	}
</style>